import React from "react";
import HeaderMenuBS from "../containers/App/HeaderMenuBS";
import GlobalStyle from "../containers/App/Common/global-styles";
import "../components/layout.css";
import Fonts from "../containers/App/Common/fonts"

export default function Terms() {
  return (
    <div
      style={{
        margin: `5.5rem 7.5vw 0 7.5vw`,
        backgroud: '#f9fafc',
        color: '#0b1d27',
      }}
    >
      <Fonts />
      <GlobalStyle />
      <HeaderMenuBS/>
      <main>
      <br/>
      <h1><strong>Terms and Conditions</strong></h1>
<h4>1.1.</h4> Welcome to Museli (the 'Application').
<p>The Application provides you with an opportunity to browse and purchase
various products that have been listed for
sale through the Application (the 'Products'). The Application provides this service by way of granting you access to the content on the Application (the 'Purchase Services'). </p>
<h4>1.2.</h4> The Application is operated by MUSELI PTY LTD (the 'Company') (ABN 53 657 020 990).
<p>Access to and use of the Application, or any of its associated Products or Purchase
Services, is provided by Museli. Please read these terms and conditions (the 'Terms')
carefully. By using, browsing and/or reading the Application, this signifies that
you have read, understood and agree to be bound by the Terms. If you do not agree
with the Terms, you must cease usage of the Application, or any of its Purchase
Services, immediately. The application is licensed, not sold, to you, and the license applies between you and the Company only. </p>
<p>Scope of license: The license grants you nontransferable access to use of the application on any supported platforms. You may not transfer, redistribute or sublicense the Licensed Application and, if you sell your device to a third party, you must remove the Licensed Application from the device before doing so. You may not copy (except as permitted by this license and the Usage Rules), reverse-engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed Application, any updates, or any part thereof (except as and only to the extent that any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open-sourced components included with the Licensed Application).</p>

<p>
  MUSELI PTY LTD is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you’re paying for.
  The Museli app stores and processes personal data that you have provided to us, to provide our Service. It’s your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the Museli app won’t work properly or at all.
  The app does use third-party services that declare their Terms and Conditions.
</p>

<p>Links to Terms and Conditions of third-party service providers used by the app:</p>
<ul>
  <li><a href="https://policies.google.com/terms/" target="_blank" rel="noopener noreferrer">Google</a></li>
  <li><a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer">Youtube</a></li>
  <li><a href="https://firebase.google.com/terms/" target="_blank" rel="noopener noreferrer">Firebase</a></li>
  <li><a href="https://www.apple.com/legal/internet-services/terms/site.html/" target="_blank" rel="noopener noreferrer">Apple</a></li>
  {/* <li><a href="https://www.facebook.com/legal/terms/plain_text_terms/" target="_blank" rel="noopener noreferrer">Facebook</a></li> */}
  <li><a href="https://www.revenuecat.com/terms/" target="_blank" rel="noopener noreferrer">RevenueCat</a></li>
</ul>


<p>You should be aware that there are certain things that MUSELI PTY LTD will not take responsibility for. Certain functions of the app will require the app to have an active internet connection. The connection can be Wi-Fi or provided by your mobile network provider, but MUSELI PTY LTD cannot take responsibility for the app not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.</p>

<h4>1.3.</h4> 
<p>Museli reserves the right to review and change any of the Terms by updating
this page at its sole discretion. When Museli updates the Terms, it will use reasonable
endeavours to provide you with notice of updates to the Terms. Any changes to
the Terms take immediate effect from the date of their publication. Before you
continue, we recommend you keep a copy of the Terms for your records.</p><h3>2. Acceptance of the Terms</h3>
<p> You accept the Terms by using or browsing the Application. You may also accept the
Terms by clicking to accept or agree to the Terms where this option is made available
to you by Museli in the user interface.</p>
<h3>3. Registration to use the Purchase Services</h3>
<h4>3.1.</h4> 
<p>In order to access the Purchase Services, you must first register as a user of
the Application. As part of the registration process, or as part of your continued use
of the Purchase Services, you may be required to provide personal information about
yourself (such as identification or contact details), including:
(a) Email address
(b) Name
(c) Password
</p>

<h4>3.2.</h4>
<p>You warrant that any information you give to Museli in the course of
completing the registration process will always be accurate, correct and up to date.
You may not use the Service and may not accept the Terms if
(a)
you are not of legal age to form a binding contract with Museli; or
(b)
you are a person barred from receiving the Purchase Services under the
laws of Australia or other countries including the country in which you are
resident or from which you use the Purchase Services.
</p>

<h3>4. Your obligations as a Member</h3>
(a) As a Member, you agree to comply with the following: You will use the Purchase Services only for purposes that are permitted by:
(i) the Terms; 
(ii) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions;
(b) you have the sole responsibility for protecting the confidentiality of your password and/or email address. Use of your password by any other person may result in the immediate cancellation of the Purchase Services;
(c) any use of your registration information by any other person, or third parties, is strictly prohibited. You agree to immediately notify Museli of any unauthorised use of your password or email address or any breach of security of which you have become aware;
(d) access and use of the Application is limited, non-transferable and allows for the sole use of the Application by you for the purposes of Museli providing the Purchase Services;
(e) you will not use the Purchase Services or Application for any illegal and/or unauthorised use which includes collecting email addresses of Members by electronic or other means for the purpose of sending unsolicited email or unauthorised framing of or linking to the Application;
(f) you agree that commercial advertisements, affiliate links, and other forms of solicitation may be removed from the Application without notice and may result in termination of the Purchase Services. Appropriate legal action will be taken by Museli for any illegal or unauthorised use of the Application;\
and (g) you acknowledge and agree that any automated use of the Application or its Purchase Services is prohibited. Product (the 'Purchase Price').
(b) Payment of the Purchase Price may be made through one of the following third party providers: Google Play Store, Apple App Store, and Stripe (the 'Payment Gateway Providers') In using the Purchase Services, you warrant that you have familiarised yourself with, and agree to be bound by, the applicable Terms and Conditions of Use, Privacy Policy and other relevant legal documentation provided by the Payment Gateway Providers.
(c) Following payment of the Purchase Price being confirmed by Museli, you will be issued with a receipt to confirm that the payment has been received and Museli may record your purchase details for future use.
(d) Museli may, at their sole discretion, provide a refund on the return of the Products within N/A days where the Product packaging is unopened and remains in a saleable condition. You acknowledge and agree that you are liable for any postage and shipping costs associated with any refund pursuant to this clause.

<h3>6. Warranty</h3>
(a)
Museli's Products come with guarantees that cannot be excluded under the
Australian Consumer Law. You are entitled to a replacement or refund for a
major failure of the Product and compensation for any other reasonably foreseeable
loss or damage. You are also entitled to have the Products repaired or replaced if
the Products fail to be of acceptable quality and the failure does not amount to a
major failure (the 'Warranty').
(b)
You may make a claim under this clause (the 'Warranty Claim') for
material
defects and workmanship in the Products within 1 from the date of purchase
(the 'Warranty Period').
(c)
In order to make a Warranty Claim during the Warranty Period, you must provide
proof of purchase to Museli showing the date of purchase of the Products,
provide a description of the Products and the price paid for the Products by sending
written notice to Museli at 202/2A Hereford St, Sydney, New South Wales, 2037
or by email at contact@museli.app.
(d)
Where the Warranty Claim is accepted then Museli will, at its sole discretion,
either repair or replace any defective Products or part thereof with a new or
be the sole and exclusive remedy available to you in addition to other rights and
under a law in relation to the Products to which this warranty relates.
(f)
All implied warranties including the warranties of merchantability and fitness for
use are limited to the Warranty Period.
(g)
The Warranty does not apply to any appearance of the supplied Products nor to
the additional excluded items set forth below nor to any supplied Products where
the exterior of which has been damaged or defaced, which has been subjected to
misuse, abnormal service or handling, or which has been altered or modified in
design or construction.<h3>7. Delivery</h3>
(a)
You acknowledge that the Purchase Services offered by Museli integrate delivery
(the 'Delivery Services') through the use of third party delivery companies (the
'Delivery Service Providers').
(b)
In providing the Purchase Services, Museli may provide you with a variety of
delivery and insurance options offered as part of the Delivery Services by the
Delivery Service Providers. You acknowledge and agree that Museli is not the
provider of these delivery and insurance options and merely facilitates your
interaction with the Delivery Service Providers in respect to providing the
Delivery Services.
(c)
In the event that an item is lost or damaged in the course of the Delivery
Services, Museli asks that you:
(a)
contact the Delivery Service Provider directly to request a refund or to claim
on any insurance options available; and
(b)
contact us by sending an email to
outlining in what way the Products
were damaged in transit so we are able to determine if the Delivery Service
Provider should be removed from the Purchase Services.<h3>8. Copyright and Intellectual Property</h3>
(a)
The Application, the Purchase Services and all of the related products of Museli
are subject to copyright. The material on the Application is protected by
copyright
under the laws of Australia and through international treaties. Unless otherwise
indicated, all rights (including copyright) in the site content and compilation of
the
(b)
Museli retains all rights, title and interest in and to the Application and all
related
content. Nothing you do on or in relation to the Application will transfer to you:
(i)
the business name, trading name, domain name, trade mark, industrial
design, patent, registered design or copyright of Museli; or
(ii)
the right to use or exploit a business name, trading name, domain name,
trade mark or industrial design; or
(iii)
a system or process that is the subject of a patent, registered design or
copyright (or an adaptation or modification of such a system or process).
(c)
You may not, without the prior written permission of Museli and the permission of
any other relevant rights owners: broadcast, republish, upload to a third party,
transmit, post, distribute, show or play in public, adapt or change in any way the
Content or third party content for any purpose. This prohibition does not extend
to
materials on the Application, which are freely available for re-use or are in the
public domain.
<h3>9. Privacy</h3>
Museli takes your privacy seriously and any information provided through your use of
the
Application and/or the Purchase Services are subject to Museli's Privacy Policy, which
is
available at <a HREF="https://museli.app/privacy/">https://museli.app/privacy</a>
<h3>10. General Disclaimer</h3>
(a)
You acknowledge that Museli does not make any terms, guarantees, warranties,
representations or conditions whatsoever regarding the Products other than
provided for pursuant to these Terms.
(b)
Museli will make every effort to ensure a Product is accurately depicted on the
Application, however, you acknowledge that sizes, colours and packaging may
differ from what is displayed on the Application.
(c)
Nothing in these Terms limits or excludes any guarantees, warranties,
representations or conditions implied or imposed by law, including the Australian
Consumer Law (or any liability under them) which by law may not be limited or
excluded.
(d)
Subject to this clause, and to the extent permitted by law:
(i)
all terms, guarantees, warranties, representations or conditions which are
or opportunity, or damage to goodwill arising out of or in connection with
the Purchase Services or these Terms (including as a result of not being
able to use the Purchase Services or the late supply of the Purchase
Services), whether at common law, under contract, tort (including
negligence), in equity, pursuant to statute or otherwise.
(e)
Use of the Application, the Purchase Services, and any of the products of Museli is
at your own risk. Everything on the Application, the Purchase Services, and the
Products of Museli, are provided to you on an "as is" and "as available" basis,
without warranty or condition of any kind. None of the affiliates, directors,
officers,
employees, agents, contributors, third party content providers or licensors of
Museli make any express or implied representation or warranty about its Content
or any products or Purchase Services (including the products or Purchase
Services of Museli) referred to on the Application. This includes (but is not
restricted to) loss or damage you might suffer as a result of any of the
following:
(i)
failure of performance, error, omission, interruption, deletion, defect, failure
to correct defects, delay in operation or transmission, computer virus or
other harmful component, loss of data, communication line failure, unlawful
third party conduct, or theft, destruction, alteration or unauthorised access
to records;
(ii)
the accuracy, suitability or currency of any information on the Application,
the Purchase Service, or any of its Content related products (including third
party material and advertisements on the Application);
(iii)
costs incurred as a result of you using the Application, the Purchase
Services or any of the Products;
(iv)
the Content or operation in respect to links which are provided for the
User's convenience;
(v)
any failure to complete a transaction, or any loss arising from e-commerce
transacted on the Application; or
(vi)
any defamatory, threatening, offensive or unlawful conduct of third parties
or publication of any materials relating to or constituting such conduct.
<h3>11. Competitors</h3>
If you are in the business of providing similar Services for the purpose of providing them to users for a commercial gain, whether business users or domestic users, then you are a competitor of MUSELI PTY LTD. Competitors are not permitted to use or access any information or content provided by the Application. If you breach this provision MUSELI PTY LTD or content on our Application. If you breach this provision, MUSELI PTY LTD will hold you
fully responsible for any loss that we may sustain and hold you accountable for all profits that you might make from such a breach.
<h3>12. Limitation of Liability</h3>
(a)
Museli's total liability arising out of or in connection with the Purchase Services
or
these Terms, however arising, including under contract, tort (including
negligence),
in equity, under statute or otherwise, will not exceed the most recent Purchase
Price paid by you under these Terms or where you have not paid the Purchase
Price, then the total liability of Museli is the resupply of information or
Purchase
Services to you.
(b)
You expressly understand and agree that Museli, its affiliates, employees, agents,
contributors, third party content providers and licensors shall not be liable to
you
for any direct, indirect, incidental, special consequential or exemplary damages
which may be incurred by you, however caused and under any theory of liability.
This shall include, but is not limited to, any loss of profit (whether incurred
directly
or indirectly), any loss of goodwill or business reputation and any other
intangible
loss.
(c)
Museli is not responsible or liable in any manner for any site content (including
the
Content and Third Party Content) posted on the Application or in connection with
the Purchase Services, whether posted or caused by users of the Application of
Museli, by third parties or by any of the Purchase Services offered by Museli.
(d)
You acknowledge that Museli does not provide the Delivery Services to you and
you agree that Museli will not be liable to you for any special, indirect or
consequential loss or damage, loss of profit or opportunity, or damage to goodwill
arising out of or in connection with the Delivery Services.
<h3>13. Termination of Contract</h3>
(i)
The Terms will continue to apply until terminated by either you or by Museli as
set
out below.
(ii)
If you want to terminate the Terms, you may do so by:
(i)
notifying Museli at any time; and
(ii)
closing your accounts for all of the Purchase Services which you use,
(iii)
Museli may at any time, terminate the Terms with you if:
(i)
you have breached any provision of the Terms or intend to breach any
provision;
(ii)
Museli is required to do so by law;
(iii)
the partner with whom Museli offered the Purchase Services to you has
terminated its relationship with Museli or ceased to offer the Purchase
Services to you;
(iv)
Museli is transitioning to no longer providing the Purchase Services to
Users in the country in which you are resident or from which you use the
service; or
(v)
the provision of the Purchase Services to you by Museli is, in the opinion of
Museli, no longer commercially viable.
(iv)
Subject to local applicable laws, Museli reserves the right to discontinue or
cancel
your membership to the Application at any time and may suspend or deny, in its
sole discretion, your access to all or any portion of the Application or the
Purchase
Services without notice if you breach any provision of the Terms or any applicable
law or if your conduct impacts Museli's name or reputation or violates the rights
of
those of another party.
(v)
When the Terms come to an end, all of the legal rights, obligations and
liabilities
that you and Museli have benefited from, been subject to (or which have accrued
over time whilst the Terms have been in force) or which are expressed to continue
indefinitely, shall be unaffected by this cessation, and the provisions of this
clause
shall continue to apply to such rights, obligations and liabilities indefinitely.
<h3>14. Indemnity</h3>
(a)
You agree to indemnify Museli, its affiliates, employees, agents, contributors,
third
party content providers and licensors from and against:
(i)
all actions, suits, claims, demands, liabilities, costs, expenses, loss and
damage (including legal fees on a full indemnity basis) incurred, suffered or
arising out of or in connection with any Content you post through the
Application;
(ii)
any direct or indirect consequences of you accessing, using or transacting
on the Application or attempts to do so and any breach by you or your<h4>15.1.Compulsory:</h4>
If a dispute arises out of or relates to the Terms, either party may not commence
any Tribunal or Court proceedings in relation to the dispute, unless the following
clauses have been complied with (except where urgent interlocutory relief is
sought).
<h4>15.2.Notice:</h4>
A party to the Terms claiming a dispute ('Dispute') has arisen under the Terms,
must give written notice to the other party detailing the nature of the dispute,
the
desired outcome and the action required to settle the Dispute.<h4>15.3.Resolution:</h4>
On receipt of that notice ('Notice') by that other party, the parties to the Terms
('Parties') must:
(a)
Within 14 days of the Notice endeavour in good faith to resolve the Dispute
expeditiously by negotiation or such other means upon which they may
mutually agree;
(b)
If for any reason whatsoever, 14 days after the date of the Notice, the
Dispute has not been resolved, the Parties must either agree upon
selection of a mediator or request that an appropriate mediator be
appointed by the President of the Australian Mediation Association, the
Conflict Resolution Service or his or her nominee;
(c)
The Parties are equally liable for the fees and reasonable expenses of a
mediator and the cost of the venue of the mediation and without limiting the
foregoing undertake to pay any amounts requested by the mediator as a
pre-condition to the mediation commencing. The Parties must each pay
their own costs associated with the mediation;
(d)
The mediation will be held in Sydney, Australia.<h4>15.4.Confidential</h4>
All communications concerning negotiations made by the Parties arising out of and
in connection with this dispute resolution clause are confidential and to the
extent
possible, must be treated as "without prejudice" negotiations for the purpose of
applicable laws of evidence.
mediation and the mediator must do so.<h3>16. Venue and Jurisdiction</h3>
The Purchase Services offered by Museli is intended to be viewed by residents of
Australia. In the event of any dispute arising out of or in relation to the Application,
you
agree that the exclusive venue for resolving any dispute shall be in the courts of
New
South Wales, Australia.<h3>17. Governing Law</h3>
The Terms are governed by the laws of New South Wales, Australia. Any dispute,
controversy, proceeding or claim of whatever nature arising out of or in any way
relating
to the Terms and the rights created hereby shall be governed, interpreted and
construed
by, under and pursuant to the laws of New South Wales, Australia, without reference
to
conflict of law principles, notwithstanding mandatory rules. The validity of this
governing
law clause is not contested. The Terms shall be binding to the benefit of the
parties
hereto and their successors and assigns.<h3>18. Independent Legal Advice</h3>
Both parties confirm and declare that the provisions of the Terms are fair and
reasonable
and both parties having taken the opportunity to obtain independent legal advice
and
declare the Terms are not against public policy on the grounds of inequality or
bargaining
power or general grounds of restraint of trade.

<h3>Proprietary Rights</h3>
<p>All content available through the Application, are the property of Museli or its licensors. No Application content may be modified, copied, distributed, framed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted, or sold in any form or by any means, in whole or in part, other than as expressly permitted in these Terms and Conditions. All rights of Museli or its licensors that are not expressly granted in these Terms and Conditions are reserved to Museli and its licensors.</p>

<h3>Severance</h3>
If any part of these Terms is found to be void or unenforceable by a Court of
competent
jurisdiction, that part shall be severed and the rest of the Terms shall remain in
force.
<br />
      If you have any queries or complaints about our terms of service please contact us at:
      compliance@museli.app
      <br/>Last updated 9th September 2023
    </main>
    </div>
  )
}